
<template>
  <CSDialog :dialog-title="`${addInspectionPlanInfo.id != null ? '修改' : '添加'}巡检计划`" dialog-width="570px"
    @onConfirm="saveInspectionPlanInfo" @onClose="closeDialog" :dialog-visible="dialogVisible" :is-submitting="isSubmitting"
  >
    <template v-slot:dialog-content>
      <div class="ibox-content">
        <div>
          <div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
              >巡检类型</label
              >
              <div class="col-sm-9 row">
                <CSSelect
                    height="40px"
                    style="width: 400px"
                    i-width="36px"
                >
                  <select
                      v-model="addInspectionPlanInfo.type"
                      style="padding-left: 6px;color: #999999"
                  >
                    <option value="">
                      请选择
                    </option>
                    <option
                        v-for="(
                            val, key
                        ) in judgmentAuthority()"
                        :key="key"
                        :value="key"
                    >
                      {{ val }}
                    </option>
                  </select>
                </CSSelect>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
              >巡检计划</label
              >
              <div class="col-sm-9 row">
                <input
                    v-model="
                        addInspectionPlanInfo.name
                    "
                    type="text"
                    placeholder="请输入"
                    class="hy-input"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
              >巡检路线</label
              >
              <div class="col-sm-9 row">
                <CSSelect
                    height="40px"
                    style="width: 100%; padding-left: 6px;"
                    i-width="36px"
                >
                  <select
                      v-model="
                          addInspectionPlanInfo.routeId
                      "
                      style="color: #999999"
                  >
                    <option value="" disabled>
                      请选择
                    </option>
                    <option
                        v-for="route in inspectRoutes"
                        :key="route.id"
                        :value="route.id"
                    >
                      {{ route.name }}
                    </option>
                  </select>
                </CSSelect>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
              >巡检日</label
              >
              <div class="col-sm-9 row">
                <CSSelect
                    height="40px"
                    style="width: 100%; padding-left: 6px;"
                    i-width="36px"
                >
                  <select
                      v-model="
                          addInspectionPlanInfo.scheduleType
                      "
                      style="color: #999999"
                  >
                    <option value="" disabled>
                      请选择
                    </option>
                    <option :value="1">
                      每天
                    </option>
                    <option :value="2">
                      每周
                    </option>
                    <option :value="5">
                      指定日期
                    </option>
                  </select>
                </CSSelect>
              </div>
            </div>

            <div
                class="form-group row"
                v-if="
                    addInspectionPlanInfo.scheduleType !=
                        '' &&
                    addInspectionPlanInfo.scheduleType !=
                        1
                "
            >
              <label class="col-sm-3 col-form-label"
              >&nbsp;</label
              >
              <div class="col-sm-9">
                <template
                    v-if="
                                                    addInspectionPlanInfo.scheduleType ==
                                                    2
                                                "
                >
                  <div class="row">
                    <label
                        v-for="n in 7"
                        :key="n"
                        class="mr-3"
                    ><input
                        type="checkbox"
                        v-model="
                                                                scheduleConfig.days
                                                            "
                        :value="n"
                    />星期{{
                        week[n]
                      }}</label
                    >
                  </div>
                </template>
                <template
                    v-if="
                                                    addInspectionPlanInfo.scheduleType ==
                                                    5
                                                "
                >
                  <div
                      v-for="(
                                                        date, index
                                                    ) in scheduleConfig.dates"
                      :key="index"
                      class="row mb-2"
                  >
                    <CSSelect
                        class="col-sm-5 mr-2"
                        style="
                                                            vertical-align: middle;
                                                        "
                        height="40px"
                        i-width="10px"
                    >
                      <select
                          v-model="date.month"
                      >
                        <option
                            value=""
                            disabled
                        >
                          选择
                        </option>
                        <option
                            v-for="n in 12"
                            :key="n"
                            :value="n"
                        >
                          {{
                            prefixAddZero(
                                n
                            )
                          }}
                        </option>
                      </select>
                    </CSSelect>
                    <CSSelect
                        class="col-sm-5 mr-2"
                        style="
                                                            vertical-align: middle;
                                                        "
                        height="40px"
                        i-width="10px"
                    >
                      <select
                          v-model="date.day"
                      >
                        <option
                            value=""
                            disabled
                        >
                          选择
                        </option>
                        <option
                            v-for="n in daysPerMonth[
                                                                    scheduleConfig
                                                                        .dates[
                                                                        index
                                                                    ].month - 1
                                                                ]"
                            :key="n"
                            :value="
                                                                    prefixAddZero(
                                                                        n
                                                                    )
                                                                "
                        >
                          {{
                            prefixAddZero(
                                n
                            )
                          }}
                        </option>
                      </select>
                    </CSSelect>

                    <span
                        class="bgSpan"
                        aria-hidden="true"
                        style="
                                                            vertical-align: middle;
                                                            display: inline-block;
                                                            line-height: 1.5;
                                                        "
                        @click="
                                                            removeScheduleConfigDate(
                                                                index
                                                            )
                                                        "
                    ></span>
                  </div>

                  <button
                      class="btn btn-primary"
                      @click="
                                                        addScheduleConfigDate
                                                    "
                  >
                    添加
                  </button>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
              >开始时间</label
              >
              <div class="col-sm-9 row">
                <CSSelect
                    class="col-sm-5 mr-2"
                    height="40px"
                    style="padding: 0"
                    i-width="36px"
                >
                  <select
                      v-model="
                                                        scheduleConfig.startDateHour
                                                    "
                      style="color: #999999"
                  >
                    <option value="" disabled>
                      时
                    </option>
                    <option
                        v-for="n in 24"
                        :value="
                                                            prefixAddZero(n - 1)
                                                        "
                        :key="n"
                        :disabled="
                                                            scheduleConfig.endDateHour !=
                                                                '' &&
                                                            scheduleConfig.endDateHour <
                                                                n - 1
                                                        "
                    >
                      {{
                        prefixAddZero(n - 1)
                      }}
                    </option>
                  </select>
                </CSSelect>
                <CSSelect
                    class="col-sm-5"
                    height="40px"
                    style="padding: 0"
                    i-width="36px"
                >
                  <select
                      v-model="
                                                        scheduleConfig.startDateMinute
                                                    "
                      style="color: #999999"
                  >
                    <option value="" disabled>
                      分
                    </option>
                    <option
                        v-for="n in 60"
                        :value="
                                                            prefixAddZero(n - 1)
                                                        "
                        :key="n"
                        :disabled="
                                                            scheduleConfig.endDateHour ==
                                                                scheduleConfig.startDateHour &&
                                                            scheduleConfig.endDateMinute <=
                                                                n - 1
                                                        "
                    >
                      {{
                        prefixAddZero(n - 1)
                      }}
                    </option>
                  </select>
                </CSSelect>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
              >结束时间</label
              >
              <div class="col-sm-9 row">
                <CSSelect
                    height="40px"
                    class="col-sm-5 mr-2"
                    style="padding: 0"
                    i-width="36px"
                >
                  <select
                      v-model="
                                                        scheduleConfig.endDateHour
                                                    "
                      style="color: #999999"
                  >
                    <option value="" disabled>
                      时
                    </option>
                    <option
                        v-for="n in 24"
                        :value="
                                                            prefixAddZero(n - 1)
                                                        "
                        :key="n"
                        :disabled="
                                                            scheduleConfig.startDateHour >
                                                            n - 1
                                                        "
                    >
                      {{
                        prefixAddZero(n - 1)
                      }}
                    </option>
                  </select>
                </CSSelect>
                <CSSelect
                    height="40px"
                    class="col-sm-5"
                    style="padding: 0"
                    i-width="36px"
                >
                  <select
                      v-model="
                                                        scheduleConfig.endDateMinute
                                                    "
                      style="color: #999999"
                  >
                    <option value="" disabled>
                      分
                    </option>
                    <option
                        v-for="n in 60"
                        :value="prefixAddZero(n - 1)"
                        :key="n"
                        :disabled="scheduleConfig.startDateHour === scheduleConfig.endDateHour && scheduleConfig.startDateMinute >= n - 1"
                    >
                      {{
                        prefixAddZero(n - 1)
                      }}
                    </option>
                  </select>
                </CSSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CSDialog>
</template>
<script>
import {
  queryInspectionRouteUrl,
  createInspectPlanUrl,
  editInspectPlanUrl, getInspectPlanScheduleDateUrl,
} from "@/requestUrl";
import { ORG_LEVEL, JOB_LEVEL, DEPARTMENT_TYPES } from "@/constant";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

export default {
    props: {
        callBackListener: String,
        callBackFunction: String,
    },
    components: {
        CSSelect,
        CSDialog,
    },
    data() {
        return {
            orgs: [],
            inspectRoutes: [],
            isSubmitting: false,
            inspectPlanType: {
                [DEPARTMENT_TYPES.PROCTER_GAMBLE]: {
                    103: "清洁",
                    104: "绿化",
                    105: "其它",
                },
                [DEPARTMENT_TYPES.CUSTOMER_SERVICE]: {
                    203: "巡查",
                    204: "其它",
                },
                [DEPARTMENT_TYPES.SECURITY]: {
                    304: "巡检",
                    305: "其它",
                },
                [DEPARTMENT_TYPES.ENGINEERING]: {
                    413: "巡检",
                    414: "维保",
                    415: "其它",
                },
            },
            week: {
                1: "一",
                2: "二",
                3: "三",
                4: "四",
                5: "五",
                6: "六",
                7: "日",
            },
            dialogVisible: false,
            daysPerMonth: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
            scheduleConfig: {
                startDateHour: "",
                startDateMinute: "",
                endDateHour: "",
                endDateMinute: "",
                dates: [],
                days: [],
            },
            addInspectionPlanInfo: {
                regionId: this.$vc.getCurrentRegion().communityId,
                orgId: this.$vc.getCurrentStaffInfo().orgId,
                operatorId: this.$vc.getCurrentStaffInfo().id,
                type: "",
                name: "",
                routeId: "",
                scheduleType: "",
                scheduleConfig: "",
            },
        };
    },
    mounted() {
        this.$vc.on(this.$route.path,
            "addInspectionPlan",
            "clear",
            this.clearAddInspectionPlanInfo
        );
        this.$vc.on(this.$route.path,
            "addInspectionPlan",
            "openAddInspectionPlanModal",
            async (params) => {
                if (params.id) {
                    this.addInspectionPlanInfo = {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        orgId: this.$vc.getCurrentStaffInfo().orgId,
                        type: params.type,
                        name: params.name,
                        routeId: params.routeId,
                        id: params.id,
                        scheduleType: params.scheduleType,
                        operatorId: this.$vc.getCurrentStaffInfo().id,
                        scheduleConfig: "",
                        enable: params.enable,
                    };

                    if (params.scheduleType === 2) {
                        let scheduleDates = params?.scheduleDates;
                        if (!scheduleDates) {
                          const data = await this.getInspectPlanScheduleDate(params)
                          scheduleDates = data.data;
                        }
                        this.$set(
                            this.scheduleConfig,
                            "days",
                            scheduleDates
                        );
                    }

                    if (params.scheduleType === 5) {
                        let dates = [];
                        params.scheduleDates.forEach((val) => {
                            dates.push({
                                month: Number(val.toString().slice(0, 2)),
                                day: Number(val.toString().slice(3)),
                            });
                        });
                        this.$set(this.scheduleConfig, "dates", dates);
                    }
                    this.$set(
                        this.scheduleConfig,
                        "startDateHour",
                        params.startTime.slice(0, 2)
                    );
                    this.$set(
                        this.scheduleConfig,
                        "startDateMinute",
                        params.startTime.slice(3)
                    );
                    this.$set(
                        this.scheduleConfig,
                        "endDateHour",
                        params.endTime.slice(0, 2)
                    );
                    this.$set(
                        this.scheduleConfig,
                        "endDateMinute",
                        params.endTime.slice(3)
                    );
                } else {
                    this.scheduleConfig = {
                        startDateHour: "",
                        startDateMinute: "",
                        endDateHour: "",
                        endDateMinute: "",
                        dates: [],
                        days: [],
                    };
                }
                this.getInspectRouteList();
                this.dialogVisible = true;
            }
        );

        this.$vc.on(this.$route.path, "addInspectionPlanInfo", "notify", (_param) => {
            if (_param.hasOwnProperty("staffId")) {
                this.addInspectionPlanInfo.staffId = _param.staffId;
                this.addInspectionPlanInfo.staffName = _param.staffName;
            }
            if (_param.hasOwnProperty("inspectionRouteId")) {
                this.addInspectionPlanInfo.inspectionRouteId =
                    _param.inspectionRouteId;
            }
        });
    },
    methods: {
        getInspectPlanScheduleDate(planInfo) {
          return this.$fly
              .post(getInspectPlanScheduleDateUrl, {
                planId: planInfo.id,
                orgId: planInfo.orgId,
                operatorId: planInfo.operatorId,
                regionId: this.$vc.getCurrentRegion().communityId,
              })
          .then(res => res)
        },
        removeScheduleConfigDate(index) {
            this.scheduleConfig.dates.splice(index, 1);
        },
        addScheduleConfigDate() {
            this.scheduleConfig.dates.push({
                month: "",
                day: "",
            });
        },
        prefixAddZero(num) {
            return num >= 10 ? num : `0${num}`;
        },
        // 获取巡检路线
        getInspectRouteList() {
            this.$fly
                .post(queryInspectionRouteUrl, {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    keyword: "",
                    operatorId: this.$vc.getCurrentStaffInfo().userId,
                    orgId: this.$vc.getCurrentStaffInfo().orgId,
                    pageNo: 1,
                    pageSize: 50,
                })
                .then((res) => {
                    this.inspectRoutes = res.data.datas;
                });
        },
        getOrgs() {
            this.$fly
                .post(queryDepartmentUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    code: "",
                    name: "",
                    dutyType: "",
                    parentCode: "",
                    level: ORG_LEVEL.DEPARTMENT,
                    pageNo: 1,
                    pageSize: 100,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }

                    this.orgs = res.data;
                });
        },
        saveInspectionPlanInfo: function () {
            let scheduleConfig = {
                startTime: `${this.scheduleConfig.startDateHour}:${this.scheduleConfig.startDateMinute}`,
                endTime: `${this.scheduleConfig.endDateHour}:${this.scheduleConfig.endDateMinute}`,
                dates: [],
                days: [],
            };
            if (this.addInspectionPlanInfo.scheduleType === 1) {
                delete scheduleConfig.dates;
                delete scheduleConfig.days;
            }
            if (this.addInspectionPlanInfo.scheduleType === 2) {
                scheduleConfig.days = this.scheduleConfig.days;
                delete scheduleConfig.dates;
            }
            if (this.addInspectionPlanInfo.scheduleType === 5) {
                delete scheduleConfig.days;
                this.scheduleConfig.dates.forEach((val) => {
                    scheduleConfig.dates.push(
                        `${this.prefixAddZero(val.month)}-${val.day}`
                    );
                });
            }

            this.addInspectionPlanInfo.scheduleConfig = JSON.stringify(
                scheduleConfig
            );

            (this.addInspectionPlanInfo.operatorId = this.$vc.getCurrentStaffInfo().id),
                this.isSubmitting = true;
                this.$fly
                    .post(
                        this.addInspectionPlanInfo.id == null ||
                            this.addInspectionPlanInfo.routeId === ""
                            ? createInspectPlanUrl
                            : editInspectPlanUrl,
                        this.addInspectionPlanInfo
                    )
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toast("操作成功");
                        this.dialogVisible = false;
                        this.clearAddInspectionPlanInfo();
                        this.$vc.emit(this.$route.path,
                            "inspectionPlanManage",
                            "listInspectionPlan",
                            {}
                        );
                    })
                    .finally(() => this.isSubmitting = false);
        },
        clearAddInspectionPlanInfo: function () {
            this.addInspectionPlanInfo = {
                regionId: this.$vc.getCurrentRegion().communityId,
                orgId: this.$vc.getCurrentStaffInfo().orgId,
                type: "",
                name: "",
                routeId: "",
                scheduleType: "",
                scheduleConfig: "",
            };
        },
        closeDialog() {
          this.dialogVisible = false;
          this.cleanInspectionPlanAddModel();
        },
        cleanInspectionPlanAddModel: function () {
            this.clearAddInspectionPlanInfo();
            //员工select2
            this.$vc.emit(this.$route.path,
                "addInspectionPlan",
                "inspectionRouteSelect2",
                "clearInspectionRoute",
                {}
            );
        },
        // 判断权限
        judgmentAuthority: function () {
            let udutyType = Number(this.$vc.getCurrentStaffInfo().dutyType);
            if ([1001, 1002].includes(udutyType)) {
                let types = {};
                for (let firstKey in this.inspectPlanType) {
                    for (let secondKey in this.inspectPlanType[firstKey]) {
                        const secondKeyMapping = {
                            105:
                                "保洁-" +
                                this.inspectPlanType[firstKey][secondKey],
                            204:
                                "客服-" +
                                this.inspectPlanType[firstKey][secondKey],
                            305:
                                "安保-" +
                                this.inspectPlanType[firstKey][secondKey],
                            415:
                                "工程-" +
                                this.inspectPlanType[firstKey][secondKey],
                        };
                        types[secondKey] =
                            secondKeyMapping[secondKey] ||
                            this.inspectPlanType[firstKey][secondKey];
                    }
                }
                return types;
            }
            return this.inspectPlanType[udutyType];
        },
    },
};
</script>
<style lang="stylus" scoped>
.bgSpan{
  background: url('../assets/delete.png');
  background-repeat:no-repeat;
  width: 30px;
  height:30px;
  margin-top: 7px;
}
.ibox-content
  font-size 24px
  color #444
  padding 30px
  select, input.form-control
    font-size 24px
    height 40px
    max-width 100%
    box-sizing border-box
    padding 0
</style>
